<template>
  <div class="video-with-title">
    <UiHeader
      v-if="header"
      class="video-with-title__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
    />

    <template v-if="video">
      <iframe
        v-if="isYouTubeVideo"
        :src="video.publicUrl"
        :title="video.properties.title ?? undefined"
        v-bind="videoAutoplay"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        class="video-with-title__player"
        :class="fullWidthClass"
      />

      <video
        v-else
        class="video-with-title__player"
        :class="fullWidthClass"
        controls
        v-bind="videoAutoplay"
      >
        <source
          :type="video.properties.mimeType"
          :src="video.publicUrl"
        />
        Your browser does not support HTML5 video.
      </video>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UiHeader } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import type { UiCeTextGalleryProps } from '~ui/types'

const props = defineProps<UiCeTextGalleryProps>()

const video = computed(() => useFlatGallery(props).value?.[0] ?? null)

const isYouTubeVideo = computed(() => {
  return video.value.properties.mimeType === 'video/youtube'
})

const videoAutoplay = computed(() => {
  return video.value.properties.autoplay ? { autoplay: true, muted: true } : {}
})

const fullWidthClass = computed(() =>
  !props.gallery?.border?.enabled ? 'video-with-title__player--full-width' : ''
)
</script>

<style lang="scss">
.video-with-title {
  &__heading + &__player {
    margin-top: rem(40px);
  }

  &__player {
    border-radius: em(4px);
    aspect-ratio: 16 / 9;
    width: 100%;
    background-color: transparent;

    &:not(&--full-width) {
      @include media-query(md) {
        display: block;
        width: 70%;
        margin-inline: auto;
      }
    }
  }
}
</style>
